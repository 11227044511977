import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject, fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, pairwise } from 'rxjs/operators';
import { SubscriptionService } from '../../services/subscription.service';

@Component({
  selector: 'app-search-input',
  templateUrl: './p-search-input.component.html',
  styleUrls: ['./p-search-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubscriptionService],
})
export class PSearchInputComponent implements AfterViewInit {
  @Input() searchValue = '';
  @Input() placeholder: string;
  @Input() hasFocus = true;
  @Input() closeAlwaysVisible = false;
  @Input() asGhostButton = false;
  @Input() debounceTime = 300;
  @Input() minTextLength = 1;
  @Output() search = new EventEmitter<string>();
  @Output() clear = new EventEmitter<string>();
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.clearSearch(event);
    }
  }

  private searchValueSubject$ = new Subject<string>();

  constructor(private subscriptionService: SubscriptionService) {}

  ngAfterViewInit(): void {
    const inputValue$ = fromEvent<Event>(this.searchInput.nativeElement, 'keyup').pipe(
      debounceTime(this.debounceTime),
      map(event => (event.target as HTMLInputElement).value),
    );

    this.subscriptionService.subscribeUntilDestroyed(
      merge(inputValue$, this.searchValueSubject$).pipe(
        distinctUntilChanged(),
        pairwise(),
        map(([prev, curr]) => {
          const normPrev = prev?.length < this.minTextLength ? '' : prev;
          const normCurr = curr?.length < this.minTextLength ? '' : curr;
          return [normPrev, normCurr];
        }),
        filter(([prev, curr]) => prev !== curr),
        map(([_, curr]) => curr),
      ),
      value => this.applySearch(value),
    );

    this.searchValueSubject$.next(this.searchValue);

    if (this.hasFocus) {
      this.setFocus();
    }
  }

  private applySearch(value: string): void {
    this.search.emit(value);
  }

  clearSearch(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.searchValue = '';
    this.searchValueSubject$.next(this.searchValue);
    this.clear.emit();
  }

  setFocus(): void {
    if (this.searchInput && this.hasFocus) {
      const inputElm = this.searchInput.nativeElement as HTMLInputElement;
      inputElm.focus();
      inputElm.select();
    }
  }
}
